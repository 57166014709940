$size: 10px;
$border: 8px;
$yellow: #ffda72;
.status {
    position: absolute;
    right: 0;
    top: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin: $border;
    &.idle,
    &.idle:before {
        background: $yellow;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -($border / 2);
        top: -($border / 2);
        width: $size + $border;
        height: $size + $border;
        border-radius: 50%;
        animation: pulse 1.5s infinite ease-in;
    }
}

@keyframes pulse {
    from {
        transform: scale(0.5);
        opacity: 1;
    }
    to {
        transform: scale(1.5);
        opacity: 0;
    }
}