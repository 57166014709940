@import url("https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@506&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";
body {
  font-family: "Signika Negative", sans-serif;
  background-color: #fafafa;
}

.App {
  text-align: center;
}

.navbar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.news-box {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.top-coins {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.bg {
  background-image: url("../assets/img/overview-bg.svg");
  margin-top: 80px;
  background-size: cover;
  background-position: center;
}

.bg-home-header {
  background-image: url("../assets/img/web-network.png");
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
}

svg {
  cursor: pointer;
  overflow: visible;
  width: 50px;
}

.box {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform: 0.5s;
}
