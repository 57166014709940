.status {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 8px;
}
.status.idle, .status.idle:before {
  background: #ffda72;
}
.status:before {
  content: "";
  display: block;
  position: absolute;
  left: -4px;
  top: -4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in;
}

@keyframes pulse {
  from {
    transform: scale(0.5);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}/*# sourceMappingURL=status.css.map */